import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  padding: 5em;
  width: 100%;
  background-color: black;
  text-align: center;
  color: white;

  .follow {
    font-size: 1.2em;
  }

  a {
    color: white;
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <div>
        Follow us on <a className="follow" href="https://www.facebook.com/groups/warwicksoulclub/" target="_blank" rel="noopener noreferrer"><strong>Facebook</strong></a>!
      </div>
      <br />
      <a href="/admin" target="_blank" rel="noopener noreferrer">
        <span itemProp="name">Admin Login</span>
      </a>
    </StyledFooter>
  );
}

export default Footer;
