import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Container, CssBaseline } from '@material-ui/core';
import styled from 'styled-components';
import Footer from './Footer';
import useSiteMetadata from './SiteMetadata';
import Header from './Header';
import GlobalStyle from './GlobalStyles';

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    flex-grow: 1;
    padding-bottom: 40px;
  }
`;

const sections = [
  { title: 'About', url: '/about' },
  { title: 'Events', url: '/events' },
  { title: 'Gallery', url: '/gallery' },
];

interface Props {
  children: ReactElement;
}

const Layout = ({ children }: Props) => {
  const { title, description } = useSiteMetadata();
  return (
    <StyledLayout>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="warwick, soul, club, warwick soul club, John Tee, No Gaps Frankie" />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://warwicksoulclub.co.uk" />
        <link href="https://fonts.googleapis.com/css?family=Josefin+Sans|Limelight&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
      <CssBaseline />
      <Container maxWidth="md">
        <Header title="Warwick Soul Club" sections={sections} />
      </Container>
      <div className="content">{children}</div>
      <Footer />
    </StyledLayout>
  );
};

export default Layout;
