import React from 'react';
import {
  Link, makeStyles, Toolbar, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
    fontFamily: "'Josefin Sans', sans-serif",
    marginRight: '15px',
    padding: '12px 0',
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    padding: '0',
    flexWrap: 'wrap',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    fontFamily: "'Limelight', cursive",
    fontSize: '1.5em',
    '&:first-child': {
      paddingLeft: 0,
    },
  },
}));

interface Props {
  sections: {
    title: string;
    url: string;
  }[];
  title: string;
}

export default function Header(props: Props) {
  const classes = useStyles('');
  const { sections, title } = props;

  return (
    <>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        <Link href="/">
          <Typography
            component="h1"
            variant="h5"
            color="inherit"
            noWrap
            className={classes.toolbarTitle}
          >
            {title}
          </Typography>
        </Link>
        <div className="nav-sections">
          {sections.map((section) => (
            <Link
              color="inherit"
              noWrap
              key={section.title}
              variant="body2"
              href={section.url}
              className={classes.toolbarLink}
            >
              {section.title}
            </Link>
          ))}
        </div>
      </Toolbar>
    </>
  );
}
