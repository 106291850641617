import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  h1 {
    font-family: 'Limelight', cursive;
  }
`;

export default GlobalStyle;
